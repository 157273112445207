<template>
	<div>
		<h2 class="text-gray-800 font-bold text-lg mb-3 font-nunito">
			Change Password
		</h2>
		<div class="mb-4">
			<p>Password must meet the following requirements:</p>
			<br />
			<ul>
				<li
					:class="[
						`${hasCapital ? 'text-green-600' : 'text-red-600'}`,
					]"
				>
					- Must contain at least 1 capital letter
				</li>
				<li
					:class="[
						`${hasNumber ? 'text-green-600' : 'text-red-600'}`,
					]"
				>
					- Must contain at least 1 number
				</li>
				<li
					:class="[
						`${hasSpecial ? 'text-green-600' : 'text-red-600'}`,
					]"
				>
					- Must contain at least 1 special character
				</li>
				<li
					:class="[
						`${hasLength ? 'text-green-600' : 'text-red-600'}`,
					]"
				>
					- Must have a minimum of 8 characters
				</li>
			</ul>
		</div>
		<form class="auth-form" @submit.prevent="onFormSubmit">
			<div class="mb-4">
				<label for="email" class="font-nunito font-medium w-full">
					Email address
				</label>
				<div class="flex justify-around items-center">
					<input
						v-model="email"
						required
						type="email"
						name="email"
						class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
					 focus:shadow-outline mr-3"
						placeholder="Enter your email"
					/>
					<span class="space-x-2 space">
						<font-awesome-icon
							v-if="
								/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
									email
								)
							"
							class="text-green-600"
							:icon="['far', 'check-circle']"
						/>
						<font-awesome-icon
							v-else
							class="text-red-600"
							:icon="['far', 'times-circle']"
						/>
					</span>
				</div>
			</div>
			<div class="mb-4">
				<div class="flex justify-between">
					<label for="password" class="font-nunito font-medium">
						Password
					</label>
				</div>
				<div class="flex justify-around items-center">
					<input
						v-model="password"
						required
						type="password"
						name="password"
						placeholder="Enter your new password"
						class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
						 focus:shadow-outline mr-3"
					/>
					<span class="space-x-2 space">
						<font-awesome-icon
							v-if="validPassword && password"
							class="text-green-600"
							:icon="['far', 'check-circle']"
						/>
						<font-awesome-icon
							v-else
							class="text-red-600"
							:icon="['far', 'times-circle']"
						/>
					</span>
				</div>
			</div>
			<div class="mb-4">
				<div class="flex justify-between">
					<label for="password" class="font-nunito font-medium">
						Confirm password
					</label>
				</div>
				<div class="flex justify-around items-center">
					<input
						v-model="password_confirmation"
						required
						type="password"
						name="password"
						placeholder="Confirm your password"
						class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
						 focus:shadow-outline mr-3"
					/>
					<span class="space-x-2 space">
						<font-awesome-icon
							v-if="
								isValid &&
									password &&
									password_confirmation &&
									password === password_confirmation
							"
							class="text-green-600"
							:icon="['far', 'check-circle']"
						/>
						<font-awesome-icon
							v-else
							class="text-red-600"
							:icon="['far', 'times-circle']"
						/>
					</span>
				</div>
				<span
					v-if="
						password &&
							password_confirmation &&
							password !== password_confirmation
					"
					class="text-red-600 text-sm"
				>
					Passwords do not match
				</span>
			</div>
			<button
				type="submit"
				:disabled="!isValid"
				:class="[
					`flex font-roboto uppercase tracking-wide mx-auto bg-blue-500 hover:bg-blue-700 text-white text-sm
					 items-center font-medium py-2 px-3 rounded focus:outline-none focus:shadow-outline ${
							!isValid
								? 'opacity-50 cursor-not-allowed'
								: 'cursor-pointer'
						}`,
				]"
			>
				<font-awesome-icon
					class="btn-icon mr-2"
					:icon="['far', 'unlock-alt']"
				/>
				<span class="btn-label">Change Password</span>
			</button>
		</form>
		<router-link
			class="flex flex-row-reverse w-25 float-right text-sm text-gray-700 mt-10 font-nunito"
			:to="{
				name: 'login',
			}"
		>
			<span class="mx-auto">Back to <strong>Login</strong></span>
		</router-link>
	</div>
</template>

<script>
export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			const { token } = this.$route.params

			try {
				await this.$api
					.password()
					.update(
						token,
						this.email,
						this.password,
						this.password_confirmation
					)

				this.$alert.success(
					'Your password has been successfully changed.'
				)
				this.$router.push({ name: 'login' })
			} catch (e) {
				this.$alert.error(
					'Your password does not comply with the requirements.'
				)
			}
		},

		allowedSpecialCharCheck(stringToMatch) {
			const regex = /[!@#$%^&*_()\-+=~`{|}[\]':;"<>,./?\\]/g
			return stringToMatch.match(regex)
		},

		validatePassword: function() {
			const password = this.password
			const password_confirmation = this.password_confirmation

			this.hasCapital =
				password.match(/[A-Z]/) && password_confirmation.match(/[A-Z]/)
			this.hasNumber =
				password.match(/[0-9]/) && password_confirmation.match(/[0-9]/)
			this.hasSpecial =
				this.allowedSpecialCharCheck(password) &&
				this.allowedSpecialCharCheck(password_confirmation)
			this.hasLength =
				password.length >= 8 && password_confirmation.length >= 8

			if (
				password.length >= 8 &&
				password.match(/[A-Z]/) &&
				password.match(/[a-z]/) &&
				password.match(/[0-9]/) &&
				this.allowedSpecialCharCheck(password)
			) {
				this.validPassword = true
				this.isValid = password === password_confirmation
			} else {
				this.isValid = false
				this.validPassword = false
			}
		},

		validateEmail() {
			this.isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
				this.email
			)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ResetPassword',

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */

	watch: {
		email: {
			handler: 'validateEmail',
			immediate: true,
		},
		password: {
			handler: 'validatePassword',
			immediate: true,
		},
		password_confirmation: {
			handler: 'validatePassword',
			immediate: true,
		},
	},

	data() {
		return {
			email: '',
			password: '',
			password_confirmation: '',
		}
	},
}
</script>
