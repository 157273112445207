var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"text-gray-800 font-bold text-lg mb-3 font-nunito"},[_vm._v(" Change Password ")]),_c('div',{staticClass:"mb-4"},[_c('p',[_vm._v("Password must meet the following requirements:")]),_c('br'),_c('ul',[_c('li',{class:[
					("" + (_vm.hasCapital ? 'text-green-600' : 'text-red-600')) ]},[_vm._v(" - Must contain at least 1 capital letter ")]),_c('li',{class:[
					("" + (_vm.hasNumber ? 'text-green-600' : 'text-red-600')) ]},[_vm._v(" - Must contain at least 1 number ")]),_c('li',{class:[
					("" + (_vm.hasSpecial ? 'text-green-600' : 'text-red-600')) ]},[_vm._v(" - Must contain at least 1 special character ")]),_c('li',{class:[
					("" + (_vm.hasLength ? 'text-green-600' : 'text-red-600')) ]},[_vm._v(" - Must have a minimum of 8 characters ")])])]),_c('form',{staticClass:"auth-form",on:{"submit":function($event){$event.preventDefault();return _vm.onFormSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"font-nunito font-medium w-full",attrs:{"for":"email"}},[_vm._v(" Email address ")]),_c('div',{staticClass:"flex justify-around items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight\n\t\t\t\t focus:shadow-outline mr-3",attrs:{"required":"","type":"email","name":"email","placeholder":"Enter your email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"space-x-2 space"},[(
							/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
								_vm.email
							)
						)?_c('font-awesome-icon',{staticClass:"text-green-600",attrs:{"icon":['far', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-red-600",attrs:{"icon":['far', 'times-circle']}})],1)])]),_c('div',{staticClass:"mb-4"},[_vm._m(0),_c('div',{staticClass:"flex justify-around items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight\n\t\t\t\t\t focus:shadow-outline mr-3",attrs:{"required":"","type":"password","name":"password","placeholder":"Enter your new password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"space-x-2 space"},[(_vm.validPassword && _vm.password)?_c('font-awesome-icon',{staticClass:"text-green-600",attrs:{"icon":['far', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-red-600",attrs:{"icon":['far', 'times-circle']}})],1)])]),_c('div',{staticClass:"mb-4"},[_vm._m(1),_c('div',{staticClass:"flex justify-around items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight\n\t\t\t\t\t focus:shadow-outline mr-3",attrs:{"required":"","type":"password","name":"password","placeholder":"Confirm your password"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),_c('span',{staticClass:"space-x-2 space"},[(
							_vm.isValid &&
								_vm.password &&
								_vm.password_confirmation &&
								_vm.password === _vm.password_confirmation
						)?_c('font-awesome-icon',{staticClass:"text-green-600",attrs:{"icon":['far', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-red-600",attrs:{"icon":['far', 'times-circle']}})],1)]),(
					_vm.password &&
						_vm.password_confirmation &&
						_vm.password !== _vm.password_confirmation
				)?_c('span',{staticClass:"text-red-600 text-sm"},[_vm._v(" Passwords do not match ")]):_vm._e()]),_c('button',{class:[
				("flex font-roboto uppercase tracking-wide mx-auto bg-blue-500 hover:bg-blue-700 text-white text-sm\n\t\t\t\t items-center font-medium py-2 px-3 rounded focus:outline-none focus:shadow-outline " + (!_vm.isValid
							? 'opacity-50 cursor-not-allowed'
							: 'cursor-pointer')) ],attrs:{"type":"submit","disabled":!_vm.isValid}},[_c('font-awesome-icon',{staticClass:"btn-icon mr-2",attrs:{"icon":['far', 'unlock-alt']}}),_c('span',{staticClass:"btn-label"},[_vm._v("Change Password")])],1)]),_c('router-link',{staticClass:"flex flex-row-reverse w-25 float-right text-sm text-gray-700 mt-10 font-nunito",attrs:{"to":{
			name: 'login',
		}}},[_c('span',{staticClass:"mx-auto"},[_vm._v("Back to "),_c('strong',[_vm._v("Login")])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between"},[_c('label',{staticClass:"font-nunito font-medium",attrs:{"for":"password"}},[_vm._v(" Password ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between"},[_c('label',{staticClass:"font-nunito font-medium",attrs:{"for":"password"}},[_vm._v(" Confirm password ")])])}]

export { render, staticRenderFns }